import { render, staticRenderFns } from "./MainInmobiliaria.vue?vue&type=template&id=2d247abb&scoped=true&"
import script from "./MainInmobiliaria.vue?vue&type=script&lang=js&"
export * from "./MainInmobiliaria.vue?vue&type=script&lang=js&"
import style0 from "./MainInmobiliaria.vue?vue&type=style&index=0&id=2d247abb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d247abb",
  null
  
)

export default component.exports